import React from 'react'
import { graphql } from 'gatsby'

import Layout from 'layouts/index'
import Seo from 'components/seo'
import accessCover from '/static/images/platform/china_access_hero.webp'
import Cover from 'components/common/cover'
import UseCase from 'components/common/useCase'
import ContentCarousel from 'components/common/contentCarousel'
import { chinaAccessFeatures } from '/static/data/platform/china-access.static'

const ChinaAccess = () => {
  return (
    <Layout>
      <React.Fragment>
        <Seo
          seo={{
            metaTitle: `Mlytics China Access: Reliable Content Delivery in China`,
            metaDescription: `Overcome the Great Firewall with Mlytics China Access. Deliver your content quickly and reliably across China with our powerful content delivery solutions.`,
            metaUrl: 'www.mlytics.com/platform/china-access',
            shareImage: {
              imageUrl: 'https://www.mlytics.com/wp-content/uploads/2023/03/og_v5.jpg',
              imageWidth: 1200,
              imageHeight: 630,
              imageType: 'image/jpeg'
            }
          }}
        />
        <section
          style={{
            height: 'calc(100vh - 100px)',
            minHeight: '600px'
          }}
          className="px-9 md:px-[86px] mb-5 lg:max-h-[800px] base:absolute base:left-1/2 base:-translate-x-1/2 base:w-screen base:max-w-[1920px]"
        >
          <Cover
            smallTitle="China Access"
            title="Access to the Inaccessible"
            imgSrc={accessCover}
            imgAlt="China Access Hero Picture"
            paragraphContent="Mlytics’ network in China allows our customers to penetrate and provide reliable access and availability to their users in China."
            actionLinkOne="/contact-us"
            actionTextOne="Learn more"
          />
        </section>
        <div className="hidden mb-5 lg:max-h-[800px] base:block base:h-[calc(100vh-100px)] base:min-h-[600px]"></div>
        <section className="py-[60px] px-9 md:px-[86px]">
          <ContentCarousel listItem={chinaAccessFeatures} />
        </section>
        <section className="py-[60px]">
          <UseCase />
        </section>
      </React.Fragment>
    </Layout>
  )
}

export default ChinaAccess

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
