import React from 'react'
import { Link } from 'gatsby'

import one from '/static/images/platform/china_access_feature_1.webp'
import two from '/static/images/platform/china_access_feature_2.webp'
import three from '/static/images/platform/china_access_feature_3.webp'
import { clickMessageBirdChat } from 'utils'

export const chinaAccessFeatures = [
  {
    title: 'Overcome ICP license challenge',
    content: `No.1 challenge for any application to perform well in China is the ICP license. Mlytics can provide accessibility to China with or without ICP license, whichever method customers prefer.`,
    imageSrc: one,
    imageAlt: 'Overcome ICP license challenge',
    children: (
      <Link to="/contact-us" className="block h5-subtext-medium text-blue-100 underline my-3 w-fit">
        Learn more.
      </Link>
    )
  },
  {
    title: 'In-China networks',
    content: `Over the years, Mlytics manages PoPs inside China which gives our customers unmatched advantages to perform city-level monitoring and delivery. With your ICP license, Mlytics can utilize our in-China networks for the best possible performance.`,
    imageSrc: two,
    imageAlt: 'In-China networks',
    children: (
      <button onClick={clickMessageBirdChat} className="bg-none h5-subtext-medium text-blue-100 underline my-3 w-fit">
        Learn more.
      </button>
    )
  },
  {
    title: 'China rim networks',
    content: `If customers like to skip ICP license trouble, Mlytics would use its outside-of-China networks to boost speed and availability of your application.`,
    imageSrc: three,
    imageAlt: 'China rim networks',
    children: (
      <button onClick={clickMessageBirdChat} className="bg-none h5-subtext-medium text-blue-100 underline my-3 w-fit">
        Learn more.
      </button>
    )
  }
]
